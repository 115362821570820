let arrRecipes = [];

    function add(title, flavor, keywords, ingredients, directions) {
        arrRecipes[arrRecipes.length] = {
            title: title,
            flavor: flavor,
            keywords: keywords,
            ingredients: ingredients,
            directions: directions
        }
    };
    add("Strawberry Banana Protein", "Sweet", ["simple", "easy", "strawberry banana", "strawberries", "protein", "banana", "bananas"], ["frozen bananas (2)", "almond milk (1 cup)", "frozen strawberries (1 cup)", "greek yogurt (1/2 cup)", "chia seeds (1 tbsp)"], "blend all - serves two.");
    add("Peanut (or almond) Butter and Banana Smoothie", "Sweet and Savory", ["simple", "easy", "peanut butter banana", "protein", "protein powder", "peanut butter", "almond butter", "banana", "bananas"], ["Ice (crushed, 1/4 cup or cubes, 4)", "almond milk (1/2 cup)", "1/2 banana", "whey protein (1 rounded scoop) (vanilla)", "Peanut (or almond) butter (2 tbsp)"]);
    add("Strawberry Mango Spring Smoothie", "Sweet", ["paleo", "simple", "easy", "healthy", "vegan", "strawberry", "strawberries", "coconut milk", "mango", "mangos", "banana", "bananas"], ["coconut milk (1 cup)", "1 banana (peeled, sliced, and frozen)", "1 mango (skinned and chunked)", "5 large strawberries (hulled)"]);
    add("Pumpkin Pie Fall Smoothie", "Sweet and Savory", ["paleo", "healthy", "vegan", "almond milk", "agave syrup", "agave", "apples", "pumpkin", "pumpkin puree", "pumpkins", "pumpkin pie", "cinnamon", "apple", "cranberry", "cranberries"], ["Almond milk (1 cup)", "Agave syrup (1 tsp)", "Pumpkin puree (1 cup)", "cinnamon (2 tsp)", "1 apple (cored)", "dried cranberries (1/4 cup)"]);
    add("Winter Greens Smoothie", "Savory", ["paleo", "vegan", "green", "carrot juice", "orange juice", "juice", "spinach", "kale", "broccoli", "bananas", "banana"], ["carrot juice (1/4 cup)", "orange juice (1/2 cup)", "Spinach (1 cup)", "kale (1 cup) (roughly chopped) (ribs removed)", "broccoli florets (4) (sliced and frozen)", "1 banana (peeled, sliced and frozen)", "1 apple (cored and roughly chopped)"]);
    add("Spinach Secret", "Sweet", ["spinach", "vegan", "green", "spinach", "banana", "peanut butter", "bananas"], ["frozen bananas (2)", "spinach (1.5 cups)", "peanut butter (3 tbsp)", "almond milk (1.5 cups)"], "blend all - serves two.");
    add("Thin Mint", "Sweet and Savory", ["healthy", "protein", "green", "almond milk", "protein powder", "oats", "spinach", "peppermint", "chocolate"], ["almond milk (1 cup)", "spinach (1 cup frozen or 2 cups fresh)", "whey protein powder (1 rounded scoop) (chocolate)", "rolled oats (1/4 cup)", "peppermint extract (1/8 tsp)"]);
    add("Radish Smoothie", "Savory", ["paleo", "healthy", "vegan", "radish", "radishes", "carrot", "carrots", "strawberry", "strawberries", "lemon juice", "lemon", "yogurt", "juice", "orange juice"], ["raw radishes (1 cup)", "carrots (1 cup)", "strawberries (1 cup)", "lemon juice (2 tbsp)", "yogurt (1 cup)", "orange juice (1/2 cup)"], "Serves 2. From best-smoothies.com");
    add("Pineapple & Ginger", "Sweet and Savory", ["yogurt", "pineapple", "pineapples", "juice", "pineapple juice", "cinnamon", "ginger"], ["plain yogurt (1 cup)", "pineapple (1 cup) (1-inch pieces) (fresh or frozen)", "ginger (1 inch piece) (peeled, minced)", "pineapple juice (1 cup)", "ground cinnamon (1/8 tsp)", "ice (1/2 cup) (if using fresh pineapple)"]);
    add("Green Ginger Peach", "Sweet and Savory", ["green", "vegan", "spinach", "honey", "water", "peach", "peaches"], ["baby spinach (2 handfuls)", "fresh ginger (grated) (peeled)", "peaches (2 cups) (frozen, sliced)", "honey (2 tsp)", "water (1 1/4 cups)"], "From marthastewart.com.");
    add("Cherry Almond Smoothie", "Sweet and Savory", ["simple", "easy", "cherries", "almond", "cherry", "almonds", "almond butter", "paleo", "vegan", "fruity"], ["almondmilk (2 cups)", "cherries (2 cups) (fresh or frozen)", "almond butter (1/4 cup - creamy, raw)"], "From wholefoodsmarket.com. Serves 4.");
    add("Strawberry Banana", "Sweet", ["banana", "bananas", "strawberry", "strawberries", "yogurt", "milk", "honey", "cinnamon"], ["banana (1)", "strawberries (1 cup)", "yogurt (1/2 cup) (vanilla)", "milk of choice (1/2 cup)", "cinnamon (pinch)", "ice (1 cup)"]);
    add("Ice Cream Kale Shake", "Sweet and Savory", ["green", "paleo", "kale", "superfood", "bananas", "banana", "nuts", "cashews", "date", "cashew", "dates", "maple syrup", "salty", "salt", "vanilla"], ["curly kale leaves (1 cup) (about 3 large leaves)", "bananas (2) (ripe)", "ice (2 cups)", "water (1/2 cup)", "cashews (raw) (1/2 cup)", "dates (chopped & pitted) (1/4 cup) OR maple syrup (2 tbsp)", "pure vanilla extract (1 tsp)", "minced ginger (1 tsp)", "sea salt (1 pinch)"], "Serves two. From healthyblenderrecipes.com.");
    add("Strawberry Shortcake", "Sweet", ["sweet", "dessert", "cake", "sweet tooth", "whipped cream", "whipping cream", "strawberry", "strawberries", "shortcake", "shortcakes", "sugar", "pound cake", "pound cakes"], ["strawberries (2 cups)", "crumbled pound cake (1 cup)", "milk (1 1/2 cups)", "ice (1 1/2 cups)", "sugar (to taste)"], "Top with whipped cream and more strawberries.");
    add("Watermelon", "Sweet", ["sweet", "healthy", "watermelon", "watermelons", "lime", "juice", "lime juice", "limes"], ["frozen cubed watermelon (3 cups) (seeded)", "lime (juice of 1)", "sugar (1/4 cup)", "water (1 cup)", ], "Freeze the cubed watermelon until hard. Blend with other ingredients.");
    add("Triple Berry Blend", "Sweet", ["paleo", "healthy", "superfood", "simple", "easy", "vegan", "sweet", "blackberry", "blackberries", "strawberry", "strawberries", "raspberry", "raspberries"], ["strawberries, blackberries and raspberries (1 cup each)", "milk of choice (for paleo: almond or coconut) (1 cup)", "ice (1 cup)"]);
    add("Spa Cucumber", "Sweet and Savory", ["paleo", "healthy", "simple", "easy", "vegan", "green", "cucumber", "cucumbers", "lime", "honey", "sugar", "water"], ["cucumbers (2) (peeled, seeded, chopped)", "lime (1 juiced)", "sugar or honey (3 to 4 tbsp)", "water (1/2 cup)", "ice (1 cup)"]);
    add("Carrot-Apple", "Sweet and Savory", ["paleo", "healthy", "carrot juice", "juice", "simple", "easy", "vegan", "carrots", "carrot", "apple", "apples"], ["carrot juice (1 cup)", "apple juice (1 cup)", "ice (1 1/2 cups)"]);
    add("Kiwi-Strawberry Smoothie", "Sweet", ["frozen yogurt", "juice", "orange juice", "orange", "pineapple juice", "pineapple", "yogurt", "simple", "easy", "green", "kiwi", "kiwis", "strawberry", "strawberries", "banana", "bananas"], ["1 kiwi", "1 banana", "6 strawberries", "vanilla frozen yogurt (1/2 cup)", "pineapple and orange juice blend (3/4 cups)"], "From allrecipes.com");
    add("Strawberry Kiwi Smoothie", "Sweet and Savory", ["paleo", "yogurt", "simple", "easy", "green", "kiwi", "kiwis", "strawberry", "strawberries", "banana", "bananas"], ["1 kiwi", "1/2 banana", "1/2 cup strawberries", "raw organic yogurt (1/4 cup)", "water (1/4 cup)"], "From www.draxe.com");
    add("Chocolate-Banana", "Sweet", ["simple", "easy", "chocolate", "ice cream", "dessert", "sweet", "sweet tooth"], ["banana (1)", "chocolate ice cream (1 cup)", "salt (pinch)", "milk of choice (1/2 cup)", "ice (1/2 cups)"]);
    add("Summer Stone Fruit Smoothie", "Sweet", ["simple", "easy", "yogurt", "healthy", "plum", "peaches", "peach", "plums", "nectarine", "blueberries", "blueberry"], ["Greek yogurt (1/2 cup)", "1 plum (pit removed) (roughly chopped)", "1 peach (pit removed) (roughly chopped)", "1 nectarine (pit removed) (roughly chopped)", "Blueberries (fresh or frozen) (1/2 cup)"]);
    add("Superfood Smoothie", "Savory", ["savory", "superfood", "spinach", "yogurt", "honeydew", "pear"], ["1/4 cups ice cubes, or as desired", "1 cup baby spinach leaves, or to taste", "1/2 cup chopped honeydew melon", "1/4 cup chopped pear", "1/4 cup milk"], "Blend all ingredients in a blender until smooth. From allrecipes.com.");
    add("Fresh Fruit Strawberry Kiwi Smoothie", "Sweet", ["yogurt", "simple", "easy", "agave", "honey", "green", "kiwi", "kiwis", "strawberry", "strawberries"], ["1 kiwi (peeled)", "6 strawberries", "strawberry or vanilla yogurt (1 cup)", "ice (1 cup)", "agave nectar or honey (1 tbsp)"], "From www.momtastic.com");
    add("Apple-Spinach", "Sweet and Savory", ["green", "healthy", "superfood", "veggies", "vegetables", "veggy", "spinach", "apple", "apples", "tofu", "juice", "orange juice"], ["spinach (2 cups)", "apple (1) (peeled) (chopped)", "orange juice (1 cup)", "milk of choice (1 cup)", "silken tofu (1/2 cup)", "wheat germ (1 tbsp)", "honey (1 tbsp)", "lemon juice (1 tbsp)", "ice (1/2 cup)"]);
    add("Paleo Breakfast Smoothie", "Sweet and Savory", ["paleo", "coconut milk", "coconut water", "healthy", "protein powder", "protein", "banana", "bananas", "flax", "chia", "blackberries", "blackberry", "blueberries", "blueberry"], ["coconut water (1 cup)", "coconut milk (1/2 cup)", "vanilla protein powder (optional) (1 scoop)", "1/2 frozen banana (optional)", "flax powder (2 tbsp)", "chia seeds (1 tbsp)", "frozen blackberries (1 cup)", "frozen blueberries (1/2 cup)"], "Add all ingredients besides chia seeds to blender. Blend until smooth. Stir in chia seeds.");
    add("Spicy Green Smoothie", "Savory", ["paleo", "green", "vegan", "spicy", "avocado", "avocados", "cucumber", "cucumbers", "collard greens", "lemon", "lemons", "vanilla", "ginger", "jalapeno", "jalapenos", "water", "beets", "beet"], ["water (3 cup)", "avocado (1)", "orange beet (1)", "cucumber (1)", "collard greens (4-6 stalks)", "juice of 1 lemon", "vanilla extract (splash)", "ginger (inch)", "jalapeno pepper (1/2 or more)"]);
    add("Tropical Blueberry", "Sweet", ["vegan", "sugar", "pineapple", "pineapples", "orange", "oranges", "pineapple", "pineapples", "blueberries", "blueberry"], ["sugar (2 tsp)", "pineapple (1 cup) (chopped)", "blueberries (1 1/2 cup) (frozen)", "orange (1) (seeded, quartered)", "water (1/2 - 3/4 cup)"]);
    add("Bell Pepper Avocado", "Savory", ["paleo", "yellow bell pepper", "bell pepper", "avocado", "bell peppers", "avocados", "icy", "yogurt", "banana", "bananas", "hemp seeds", "hemp"], ["yellow bell pepper (2/3 cup or about 1/2 bell pepper)", "banana (1)", "avocado (1)", "fat free yogurt (1.5 cups)", "water (1/3 cups)", "ice cubes (6)", "hemp seeds (2 tsp or 10ml)"], "From best-smoothies.com");
    add("Strawberry Cucumber Smoothie", "Sweet", ["green", "strawberry", "vegan", "strawberries", "cucumber", "cucumbers", "honey", "lemon"], ["1 & 1/2 cup frozen strawberries", "1 cup almond milk", "1 to 2 tbsp honey", "1/2 an english cucumber (Deseeded & chopped)", "squeeze of lemon"], "Mix all ingredients in a blender until smooth and creamy. Add milk if too thick.  Serves two. From joythebaker.com.");
    add("Salty Alkaline Kale Green Smoothie", "Savory", ["paleo", "healthy", "superfood", "veggies", "vegetables", "veggy", "green", "vegan", "kale", "tomato", "tomatoes", "cucumber", "cucumbers", "avocado", "avocados", "lime", "limes", "water", "broccoli", "sprouts", "creamed coconut", "coconut", "garlic", "salt"], ["kale (2 cups firmly packed)", "tomato (chopped roughly) (1)", "cucumber (chopped roughly) (1)", "avocado (pitted and chopped) (1)", "limes (peeled and quartered) (2)", "ice (1 cup)", "water (2 cups)", "broccoli sprouts (1/2 cup)", "creamed coconut (1 inch piece)", "garlic (4 cloves)", "salt of choice (1 tsp)"], "Serves two. From healthyblenderrecipes.com.");
    add("Banana Coconut", "Sweet and Savory", ["paleo", "vegan", "yummo", "banana", "bananas", "coconut", "coconut water", "shredded coconut", "date", "nuts", "dates", "vanilla", "vanilla essence", "salt", "lettuce"], ["lettuce (2 cups) (chopped)", "bananas (frozen or ripe) (4)", "coconut water (2 cups)", "shredded coconut (1/2 cup) (dried) (unsweetened)", "dates (chopped) (1 or 2)", "vanilla essence (non-alcoholic) (1 tsp)", "salt of choice (pinch)"], "Tweak flavors to taste and you might want to add dates or sugar to heighten the banana flavor depending on the ripeness of your fruit. Serves three. From healthyblenderrecipes.com.");
    add("Matcha Green Tea Fruit Smoothie", "Sweet and Savory", ["paleo", "tea", "green tea", "matcha", "matcha tea", "banana", "bananas", "mango", "mangos", "greek yogurt", "vanilla", "peanut butter", "chia", "chia seeds", "almond milk", "coconut water", "coconut", "pineapple", "pineapples"], ["1 frozen banana", "frozen mango chunks (1/2 cup)", "frozen pineapple chunks (1/2 cup)", "nonfat vanilla Greek yogurt (1/3 cup)", "unsalted peanut butter (1 tbsp)", "chia seeds (1 tsp)", "matcha powder (1/2 tsp)", "unsweetened almond milk (1/2 - 1 cup)", "splash coconut water", "ice cubes for desired thickness"], "From amandamemoranda.blogspot.com.");
    add("Kale Carrot", "Savory", ["paleo", "vegan", "healthy", "superfood", "veggies", "vegetables", "veggy", "kale", "carrot", "carrots", "orange", "oranges", "berries", "mixed berries", "apple", ], ["kale (8 packed cups)", "carrots (2 large)", "orange (1)", "berries (mixed) (1 cup)", "apple (1/2) (optional)", "water (2 or three cups depending on desired thickness)"], "Serves four.");
    add("Spicy Vegetable Greeny", "Savory", ["paleo", "healthy", "spicy", "vegan", "superfood", "veggies", "vegetables", "veggy", "lettuce", "spinach", "tomato", "tomatoes", "coconut", "coconut water", "carrot", "carrots", "cucumber", "cucumbers", "avocado", "avocados", "lime", "lime juice", "limes", "garlic"], ["romaine lettuce or spinach (2 cups) (chopped)", "tomato (1)", "coconut water (1 cup)", "chopped carrot (1 cup)", "cucumber (1 whole)", "avocado (1)", "salt of choice (1 tsp)", "garlic (2 cloves)", "cayenne pepper (pinch)", "ice cubes (3 or 4)"], "Serves two. From healthyblenderrecipes.com.");
    add("Bulletproof Banana", "Sweet and Savory", ["paleo", "coffee", "protein", "protein powder", "banana", "bananas", "coconut", "coconut butter", "caffeine", "almond butter", "vanilla"], ["black coffee (1 cups)", "vanilla protein powder (1/2 scoop)", "frozen banana chunks (8-10)", "coconut butter (1-2 tbsp)", "almond butter (1 tbsp)", "coconut water (1 cup)", "chopped carrot (1 cup)", "cucumber (1 whole)", "avocado (1)", "salt of choice (1 tsp)", "garlic (2 cloves)", "cayenne pepper (pinch)", "ice cubes (3 or 4)"], "From occasionalfaleo.com.");
    add("Cherry and Kale", "Sweet and Savory", ["kale", "healthy", "superfood", "veggies", "vegetables", "veggy", "cherry", "cherries", "orange juice", "juice", "hemp seed", "hemp seeds", "coconut oil"], ["cherries (1 cup)", "orange juice (1 cup)", "kale leaves (1 cup)", "hemp seed (2 tbsp)", "coconut oil (1 tbsp)", "ice (1 cup)"]);
    add("Chocolate Almond Dream Smoothie", "Sweet", ["chocolate", "almond", "sweet", "dessert", "chocolate", "banana", "bananas", "yogurt", "almond butter", "cocoa", "cocoa powder", "flaxseed", "flax", "vanilla"], ["1 frozen banana", "vanilla soy yogurt (6 oz)", "soymilk, ricemilk, or almondmilk (3/4 cup)", "creamy almond butter (2 tbsp)", "cocoa powder (1 tbsp)", "flaxseed meal (1 tbsp)", "chocolate or vanilla protein powder (1 tbsp)"], "From wholefoodsmarket.com. Serves 2.");
    add("Blueberry and Avocado Power Smoothie", "Sweet and Savory", ["paleo", "healthy", "superfood", "veggies", "vegetables", "veggy", "blueberry", "icy", "coconut oil", "blueberries", "avocado", "avocados", "apple", "apples", "coconut", "yoghurt", "yogurt", "almond butter"], ["blueberries (1 cup)", "avocado (1/2)", "apple (1) (cored and diced)", "water or coconut milk (1 cup)", "plain yoghurt (1/2 cup)", "almond butter (1 tbsp)", "coconut oil (1 tbsp)", "ice (1 cup)"], "From thingsmybellylikes.com.");
    add("Banana Chai", "Sweet", ["paleo", "vegan", "banana", "bananas", "chai", "coconut", "coconut milk", "cloves", "vanilla", "cinnamon", "ginger"], ["bananas (2 frozen)", "coconut milk (2 tbsp)", "vanilla (1/4 tsp)", "cinnamon (1/4 tsp)", "cloves (1/4 tsp)", "ginger (1/4 tsp)", "water (1 cup)"], "From wholefoodsimply.com.");
    add("Oatmeal Smoothie", "Sweet and Savory", ["oatmeal", "juice", "yogurt", "banana", "bananas", "rolled oats", "raspberry", "icy", "raspberries", "oats", "honey", "coconut water"], ["raspberries (1/2 cup)", "plain yogurt (1/2 cup)", "banana (1)", "rolled oats (1/2 cup)", "honey (1 tbsp)", "coconut water or juice (1 cup)", "ice (1 cup)"], "Serves 2-4. From marthastewart.com.");
    add("Heart Healthy Red", "Sweet and Savory", ["paleo", "healthy", "vegan", "cabbage", "bell pepper", "bell peppers", "tomato", "tomatoes", "strawberry", "strawberries", "raspberries", "raspberry", "water"], ["red cabbage (1 cup) (chopped)", "bell pepper (1/2)", "tomato (1)", "strawberries (5)", "raspberries (1/2 cup)", "water (8 oz)", "ice cube (1) (optional)"], "From thefitchen.com.");
    add("Chocolate Bacon", "Sweet and Savory", ["paleo", "vegan", "honey", "maple syrup", "chocolate", "banana", "bananas", "bacon", "coconut", "coconut milk"], ["coconut milk (1 cup)", "cocoa powder (1 tbsp)", "honey or maple syrup (1 tbsp)", "banana (1)", "bacon (4 strips)", "ice cubes (6-9)"], "From thepaleomama.com.");
    add("Spinach Cucumber", "Savory", ["paleo", "healthy", "vegan", "superfood", "veggies", "vegetables", "veggy", "green", "simple", "easy", "spinach", "kiwi", "kiwis", "cucumber", "cucumbers", "lemon", "juice", "apple juice"], ["raw spinach (4 cups)", "kiwis (2)", "cucumber (1/4)", "fresh lemon juice (1/2 tbsp)", "apple juice (1 3/4 cups)"], "From best-smoothies.com");
    add("Fairy Smoothie", "Sweet and Savory", ["paleo", "vegan", "simple", "easy", "raspberry", "raspberries", "banana", "bananas", "spinach"], ["water (1 cup)", "banana (1)", "raspberries (1 cup)", "spinach (1 handful)"], "From wholefoodsimply.com.");
    add("Creamy Orange Julius", "Sweet", ["paleo", "vegan", "simple", "easy", "orange", "oranges", "coconut", "coconut milk", "vanilla", "honey"], ["oranges (3-4) (de-veined and de-seeded)", "coconut milk (1 can)", "vanilla (1 tbsp)", "honey (2 tbsp)", "ice cubes for thickness"], "From julesfuel.com.");
    add("Cantaloupe Smoothie", "Sweet", ["paleo", "vegan", "simple", "easy", "cantaloupe", "lime", "limes", "sugar", "sweet"], ["cantaloupe (2 cups) (chopped)", "juice From lime (1/2)", "sugar (3 tbsp)", "water (1/2 cup)", "ice for desired thickness"]);
    add("Strawberry, Mango, & Yogurt", "Sweet", ["simple", "easy", "yogurt", "juice", "apple juice", "mango", "strawberry", "strawberries"], ["apple juice (1 1/4 cups)", "plain yogurt (1 cup)", "strawberries (1 cup)", "mango chunks (frozen or fresh) (2 cups)"], "Serves two. From marthastewart.com.");
    add("Pineapple Mango", "Sweet", ["paleo", "vegan", "simple", "easy", "pineapple", "pineapples", "coconut water", "coconut", "coconuts", "mango", "mangos", "sweet"], ["pineapple (1 cup) (chopped)", "mango (1 cup) (chopped)", "coconut water (1 cup)", "allspice (dash) (optional)"]);
    add("Pomegranate Berry", "Sweet", ["paleo", "healthy", "vegan", "simple", "easy", "superfood", "veggies", "vegetables", "veggy", "pomegranate", "pomegranates", "blueberry", "blueberries", "honey", "beet", "beets", "juice", "beet juice", "pomegranate juice", "sweet"], ["blueberries (1 cup)", "beet juice (3/4 cup)", "pomegranate juice (3/4 cup)", "honey (to taste)", "ice (1 cup)"]);
    add("Kale Avocado", "Savory", ["paleo", "juice", "healthy", "vegan", "green", "peen", "kale", "chia seeds", "chia", "apple juice", "avocado", "avocados", "icy"], ["kale (1.5 cups)", "avocado (1)", "chia seeds (1 tbsp)", "apple juice (1.5 cups)", "ice cubes (6)"], "From best-smoothies.com");
    add("Creamsicle Smoothie", "Sweet", ["dessert", "sweet", "simple", "easy", "creamsicle", "orange", "oranges", "tangerine", "tangerines", "vanilla", "vanilla ice cream", "ice cream"], ["frozen orange or orange-tangerine concentrate (3/4 cup)", "cold water (1/2 cup)", "vanilla ice cream (1 cup)", "ice (1 cup)"]);
    add("Honeydew-Almond", "Sweet and Savory", ["simple", "easy", "paleo", "vegan", "honey", "honeydew", "honeydew melon", "almond", "almond milk"], ["honeydew melon (2 cups) (chopped)", "almond milk (1 cup)", "honey (to taste)", "ice (1 cup)"]);
    add("Lean Green Smoothie", "Sweet and Savory", ["simple", "easy", "healthy", "paleo", "vegan", "green", "orange", "oranges", "pineapple", "pineapples", "kale", "kombucha", "mango kombucha"], ["oranges (2) (peeled)", "pineapple (2 cups) (chopped)", "kale (6 leaves) (stalks removed)", "mango kombucha (2 cups)", "water (2 cups)"], "From gi365.com.");
    add("Fruit Smoothie", "Sweet", ["simple", "easy", "juice", "healthy", "paleo", "vegan", "orange", "oranges", "raspberries", "blueberries", "blueberry"], ["water or juice (1/4 cup)", "navel oranges (peeled, pitted) (chunks)", "raspberries (fresh or frozen) (1 cup)", "blueberries (fresh or frozen) (1 cup)"], "If you use fresh fruit, add 1/2 cup crushed ice.");
    add("Spinach Melon Smoothie", "Savory", ["healthy", "green", "simple", "easy", "spinach", "melon", "celery", "melons", "milk", "yogurt", "honeydew", "honeydew melon"], ["frozen spinach (1 cup)", "honeydew melon (1 cup)", "celery (1 stalk)", "skim milk (1 cup)", "fat free yogurt (3/4 cups)"], "From best-smoothies.com");
    add("Savory Garden Green Smoothie", "Savory", ["savory", "healthy", "paleo", "superfood", "veggies", "vegetables", "veggy", "vegan", "green", "spinach", "cucumber", "cucumbers", "celery", "bell pepper", "cherry tomatoes", "tomato", "tomatoes", "avocado", "avocados", "basil", "garlic", "tabasco", "spicy", "shallot", "shallots"], ["water (1 cup)", "spinach leaves (handful)", "orange bell pepper (handful)", "yellow bell pepper (handful)", "cherry tomatoes (handful)", "avocado (1/2)", "garlic cloves (2)", "shallot (1/2)", "basil (a few sprigs)", "ice (1 cup)", "sea salt (to taste)", "tabasco (a few shakes)"], "From veganista.com.");
    add("Get Over It Green Protein Shake", "Sweet and Savory", ["simple", "easy", "banana", "mango", "mangos", "spinach", "kale", "superfood", "paleo", "vegan", "veggies"], ["almondmilk, soymilk, OR ricemilk (1 cup)", "mango (1, cut into chunks OR 2 cups if frozen)", "spinach or kale (2 cups, chopped, remove stems)", "banana (1)", "protein powder of choice (1 tbs)"], "From wholefoodsmarket.com. Serves 2.");
    add("Spicy Tomato Parsley", "Savory", ["paleo", "juice", "spicy", "veggies", "vegetables", "veggy", "vegan", "green", "tomato", "tomatoes", "parsley", "avocado", "avocados", "lemon juice", "onion", "red onion", "sun dried tomatoes", "sun-dried tomatoes", "sun-dried tomato", "sun dried tomato", "serrano", "serranos", "serrano chili", "serrano chilis", "garlic", "olive oil", "extra virgin olive oil"], ["roma tomatoes (5)", "ice (2 cups) ", "flat-leaf parsley (1 cup)", "avocado (1)", "lemon juice (3 tbsp)", "olive oil of choice (3 tsp)", "red onion (2 tsp)", "garlic (1 1/2 tsp) (minced)", "sun-dried tomatoes (1 tsp)", "serrano chili (3/4 tsp or more)", "salt of choice (3/4 tsp)"], "Serves three. From healthyblenderrecipes.com.");
    add("Georgia Peach Smoothie", "Sweet", ["simple", "peach", "fruity", "peaches", "yogurt", "greek yogurt"], ["2 small peaches, pitted and diced", "1/2 cup coconut milk", "1/2 cup Greek yogurt", "1/4 cup 1% milk"], "Blend until smooth.");
    add("Hearty Fruit & Oat", "Sweet and Savory", ["healthy", "yeaugh!", "yogurt", "strawberry", "strawberries", "oats", "banana", "bananas", "maple syrup", "almonds"], ["vanilla yogurt (1 cup)", "strawberries (1 cup) (quartered)", "1 banana (sliced)", "almonds (raw) (1/4 cup)", "old-fashioned oats (1/2 cup)", "maple syrup (1 tsp)"]);
    add("Nate's Gains Smoothie", "Sweet and Savory", ["easy", "bananas", "berries", "banana", "peanut butter", "almond butter", "milk", "whole milk", "protein", "gains", "power", "vanilla"], ["whole milk (1.5 cups)", "1 frozen banana (sliced)", "frozen mixed berries (1/2 cups)", "peanut or almond butter (2 tbsp)", "vanilla protein powder (whey, 1 rounded scoop)", "ice (desired for thickness)"], "Bro, do you even lift?");
    add("Green Smoothie Bowl", "Sweet and Savory", ["paleo", "green", "vegan", "bananas", "banana", "spinach", "stevia", "almond milk", "coconut oil"], ["1 frozen banana", "baby spinach (1 cup)", "almond milk (1/2-3/4 cup unsweetened)", "1 packet Stevia", "coconut oil (1/2 tbsp)", "ice for desired thickness", "Toppings: banana slices, granola, chia seeds, shredded coconut"], "Put smoothie into bowl and add toppings. Voila! From amandamemoranda.blogspot.com.");
    add("Spicy Tomato", "Savory", ["paleo", "vegan", "green", "superfood", "veggies", "vegetables", "veggy", "tomato", "tomatoes", "cucumber", "cucumbers", "avocado", "avocados", "spinach", "hot sauce", "spicy", "pepper", "lemon", "lemons", "raw spinach", "black pepper"], ["tomato (1/2 cup) (chopped)", "cucumber (1/4 cup)", "avocado (1/2)", "frozen spinach (1/3 cup) or raw spinach (handful)", "hot sauce of choice or black pepper (1 tsp or to taste)", "lemon (squeeze)", "ice (1/2 cup)"], "From marksdailyapple.com.");
    add("Avocado Pear Smoothie", "Sweet and Savory", ["green", "juice", "pear", "pear juice", "pears", "healthy", "paleo", "avocado", "avocados", "tofu", "honey", "vanilla"], ["pear juice (1 cup)", "avocado (1)", "silken tofu (drained) (1/2 cup)", "honey (2 tbsp)", "pure vanilla extract (1/2 tsp)", "ice (2 cups)"], "Serves four. From marthastewart.com.");
    add("Berry Breakfast", "Sweet and Savory", ["paleo", "healthy", "vegan", "raspberry", "raspberries", "honey", "almond milk", "flaxseed", "flaxseeds", "ginger", "lemon juice", "lemon", "cherry", "cherries"], ["raspberries (1 cup) (unsweetened, pitted, frozen)", "cherries (or raspberries) (1/4 cup) (frozen)", "honey (1 1/2 tbsp)", "lemon juice (1-2 tsp)", "ginger (2 tsp) (finely grated)", "almond or rice milk (3/4 cup)"], "From prevention.com. Serves two.");
    add("Mango & Yogurt", "Sweet", ["simple", "easy", "yogurt", "mango", "mangos", "cinnamon", "honey", "lime"], ["plain yogurt (1 1/2 cups)", "frozen mango chunks (2 1/2 cups)", "ground cinnamon (1/4 tsp)", "honey (1 tbsp)", "juice from lime (1/2 lime)"], "From marthastewart.com.");
    add("The Super Green", "Sweet and Savory", ["paleo", "healthy", "vegan", "superfood", "veggies", "vegetables", "veggy", "green", "kale", "mango", "mangos", "celery", "juice", "orange juice", "orange", "parsley", "mint"], ["kale leaves (1 1/4 cup) (chopped) (stems, ribs removed)", "mango (frozen, cubed) (1 1/4 cup)", "celery (2 medium ribs)", "orange juice (1 cup)", "parsley (1/4 cup) (chopped)", "mint (1/4 cup) (chopped)"], "From prevention.com. Serves two.");
    add("Hale to the Kale", "Sweet and Savory", ["paleo", "superfood", "veggies", "vegetables", "veggy", "healthy", "vegan", "green", "kale", "pear", "avocado", "cucumber", "avocados", "pears", "lemon", "lemons", "cucumbers", "cilantro", "ginger", "coconut water", "coconut", "protein powder", "water"], ["kale (1 cup) (packed)", "pear (1/2)", "avocado (1/4)", "cucumber (1/2)", "lemon (1/2)", "cilantro (handful)", "ginger (1/2 inch)", "coconut water (1/2 cup)", "protein powder (1 scoop)"], "From katrinevanwyk.com.");
    add("Pineapple-Citrus Smoothie", "Sweet", ["paleo", "vegan", "healthy", "simple", "easy", "pineapple", "pineapples", "carrot", "carrot juice", "juice", "carrots", "banana", "bananas", "frozen banana", "frozen pineapple", "tangerine", "tangerines", "tangerine juice", "orange", "orange juice"], ["orange-tangerine juice blend (3/4 cup)", "carrot juice (1/4 cup)", "frozen canned pineapple chunks (1 cup)", "frozen banana (1/2)"], "From goodhousekeeping.com.");
    add("Winter Smoothie", "Sweet", ["yogurt", "pineapple juice", "juice", "oranges", "orange", "blueberries", "blueberry"], ["plain yogurt (3/4 cup)", "pineapple juice (2 1/2 cups)", "blueberries (1 1/2 cup) (frozen)", "orange (1) (seeded, quartered)", "water (1/2 - 3/4 cup)"], "Serves four. From marthastewart.com.");
    add("Raspberry Blackberry Smoothie", "Sweet", ["healthy", "banana", "bananas", "vanilla", "yogurt", "raspberry", "raspberries", "blackberry", "blackberries", "honey", "icy", "cold"], ["vanilla yogurt (6 oz)", "banana (1)", "raspberries (fresh, 1 cup)", "blackberries (1/2 cup)", "ice cubes (4)"], "Makes 2 cups. From Allrecipes.com");
    add("Cranberry Smoothie", "Sweet", ["banana", "berries", "cranberry", "cranberries", "cranberry juice", "juice", "yogurt", "vanilla yogurt", "agave"], ["1 banana", "cranberry juice (1/2 cup - 100%, no sugar added)", "frozen berries (1 cup)", "water (1/2 cup)", "nonfat vanilla yogurt (1/2 cup)", "agave to taste"], "From amandamemoranda.blogspot.com.");
    add("Yogurt-Pistachio", "Savory", ["yogurt", "salty", "savory", "ginger", "pistachios", "pistachio"], ["plain yogurt (2 cups)", "water (1/2 cup)", "fresh ginger (1 1/2 tsp) (finely grated)", "pistachios (1/2 cup) (salted)", "pepper (1/4 tsp)", "ice cubes (4)", "pistachios (1/4 cup) (finely chopped)"], "Blend yogurt, water, ginger, salted pistachios, pepper, and ice cubes until smooth. Divide among glasses. Garnish with finely chopped pistachios.", "Serves six. From marthastewart.com.");
    add("Low-Carb Chocolate Peanut Butter Smoothie", "Sweet and Savory", ["low carb", "vegan", "almond milk", "peanut butter chocolate", "icy", "vanilla", "protein", "whey", "whey protein", "whipped cream", "vanilla extract", "stevia", "cocoa", "cocoa powder", "peanut butter", "chocolate", "chocolaty", "whipping cream", "peanut buttery"], ["almond milk (1 cup)", "ice (2 cups)", "peanut butter (2 tbsp)", "cocoa powder (2 tbsp)", "vanilla whey protein powder (1 scoop)", "whipped cream (2 tbsp)", "vanilla extract (1/4 tsp)", "stevia powder (1 packets or 1g) (optional)"], "Makes 3 servings. From Allrecipes.com");
    add("Kiwi Raspberry Smoothie", "Sweet and Savory", ["paleo", "simple", "easy", "vegan", "kiwi", "kiwis", "raspberry", "raspberries", "soy", "soy milk", "chia seeds", "chia", "orange juice", "juice", "orange", "oranges"], ["kiwis (4)", "raspberries (1 cup) (frozen)", "orange juice (3/4 cup)", "soy or almond milk (1 cup)", "chia seeds (2 tsp)"], "From best-smoothies.com");
    add("Healthy Strawberry Smoothie", "Sweet", ["sweet", "strawberry", "strawberries", "yogurt", "plain yogurt", "oats", "honey", "banana"], ["1 cup almond milk", "1 chopped frozen banana", "1/2 cup frozen strawberries", "1/3 cup plain fat-free Greek yogurt", "3 tablespoons rolled oats", "1 tablespoon slivered almonds"], "Blend all ingredients in a blender until smooth. From allrecipes.com.");
    add("Grapefruit Smoothie", "Sweet and Savory", ["grapefruit", "spinach", "ginger", "flax", "flax seeds"], ["3 grapefruit, peeled and sectioned", "1 cup cold water", "3 ounces fresh spinach", "6 ice cubes", "1 (1/2 inch) piece peeled fresh ginger", "1 teaspoon flax seeds"], "From www.allrecipes.com.");
    add("Super Spinach Smoothie", "Sweet and Savory", ["green", "paleo", "vegan", "banana", "spinach", "honey", "grass", "green grass powder", "grass powder", "powder"], ["1 ripe banana (best frozen)", "1 cup spinach leaves", "1 tbsp honey", "1 tbsp amazing green grass powder", "3/4 cup almond milk"], "Mix all ingredients in a blender until smooth and creamy. Add milk if too thick.  Serves one. From joythebaker.com.");
    add("Superfood Shake", "Sweet and Savory", ["healthy", "superfood", "super food", "cherry", "cherries", "beets", "beet", "strawberry", "strawberries", "banana", "whey protein", "whey", "protein", "flaxseed", "flax", "chocolate"], ["cherries (1/2 cup) (frozen)", "raw beets (1/2 cup) (chopped)", "strawberries (1/2 cup)", "banana (1/2)", "chocolate whey protein (1 scoop)", "ground flaxseed (1 tbsp)"]);
    add("Matcha Pear Green Protein Smoothie", "Savory", ["paleo", "simple", "easy", "green tea", "matcha", "matcha tea", "matcha tea powder", "matcha powder", "green tea", "green", "tea", "almond milk", "spinach", "pears", "pear"], ["whey vanilla protein powder (1 rounded scoop)", "almond milk (1 cup)", "spinach (1 cup)", "pear (1, cored)", "matcha tea powder (1/2 tsp)"], "From dailyburn.com");
    add("Spring Detox Smoothie", "Savory", ["paleo", "tea", "green tea", "cilantro", "detox", "detoxifying", "healthy", "green", "kale", "cucumber", "cucumbers", "pineapple", "pineapples", "ginger", "gingers", "avocado", "avocados", "baby kale", "organic", "organic kale"], ["green tea (chilled, 1 cup)", "cilantro (1 cup loosely packed)", "organic baby kale (1 cup loosely packed)", "cucumber (1 cup, chopped)", "pineapple (1 cup, chopped)", "ginger (1 tbsp, grated)", "avocado (1/2)"], "From hellonatural.com. This smoothie has naturally detoxifying veggies and fruit.");
    add("Banana Mocha Shake", "Sweet", ["coffee", "simple", "easy", "banana", "cocoa", "bananas", "chocolate", "dessert", "sweet", "almond milk"], ["2 ripe bananas", "almond milk (1/2 cup)", "cocoa powder (1 tbsp unsweetened)", "coffee (brewed and cooled - 1/2 cup)", "4-6 ice cubes for desired thickness"], "From amandamemoranda.blogspot.com.");
    add("Get Your Greens Smoothie", "Savory", ["simple", "easy", "cherries", "berries", "cherry", "spinach", "green", "superfood", "paleo", "vegan", "veggies"], ["almondmilk, soymilk, ricemilk or hempmilk (1.5 cups)", "baby spinach (1.5 cups)", "frozen cherries or berries (1.5 cups)"], "From wholefoodsmarket.com. Serves 2.");
    add("Morning Greens", "Sweet and Savory", ["banana", "mango", "flax", "chia", "seeds", "peanut butter", "superfood", "paleo", "vegan", "veggies", "spinach", "bananas", "mangos"], ["almond milk (1 cups)", "baby spinach (1 cup)", "frozen mango chucks (1/2 cups)", "frozen banana slices (1 banana)", "chia or flax seeds (2 tbsp)", "peanut butter (1 tbsp)"], "Serves 1.");
    add("Green Breakfast Smoothie", "Savory", ["green", "paleo", "vegan", "banana", "bananas", "pineapple", "kale", "spinach", "apple", "apples", "flax", "chia", "hemp", "hemp heart", "water"], ["spinach or kale (2 cups)", "pineapple (2 cups)", "1 banana", "1/2 apple (cored)", "hemp hearts, chia or flax seeds (2 tbsp)", "water (2 cups)"], "Serves two. From www.theendlessmeal.com");
    add("Berry Almond Smoothie", "Sweet", ["banana", "bananas", "nutiva", "mixed berries", "berry", "berries", "almond", "almonds", "seeds", "flax seeds", "blackberries", "blackberry", "raspberry", "raspberries", "honey", "coconut oil", "strawberry", "strawberries"], ["almond milk (1.5 cups)", "1 banana", "frozen mixed berries (3/4 cup)", "Nutiva® Organic Superseed Blend (or generic) (2 tbsp)", "Nutiva® Organic Virgin Coconut Oil (or generic) (1 tbsp)", "honey (1 tbsp)"], "Serves 2. From kitchen.nutiva.com");
    add("Strawberry Pina Colada Smoothie", "Sweet", ["banana", "nutiva", "bananas", "pineapple", "pineapples", "milk", "coconut", "coconut milk", "MCT oil", "MCT", "Coconut Manna"], ["milk of choice (coconut is best) (1.5 cups)", "1 frozen ripe banana", "strawberries (1.5 cups, hulled)", "Nutiva® Organic Coconut Manna (or generic coconut butter) (2tbsp)", "frozen pineapple (1.5 cups)", "Nutiva® Organic MCT Oil (or generic MCT oil) (1 tbsp)"], "Serves 2. From kitchen.nutiva.com");
    add("Terri's Spicy Vegetable Smoothie", "Savory", ["green", "spinach", "carrot", "carrots", "celery", "tomato", "apple", "apples", "lime", "ginger", "flaxseed", "flax", "worcestershire", "hot sauce"], ["fresh spinach (1/2 cup)", "carrot (1, chopped)", "celery (1, ribbed)", "tomato (1, halved)", "1/2 apple (cored)", "1/2 lime (juiced)", "ground flaxseed (1 tbsp)", "1 piece ginger (1/2 inch, peeled)", "1 pinch seasoning blend", "1 dash hot sauce", "1 dash Worcestershire sauce", "water to cover"], "From Terri Myers.");
    add("Cinnamon Apple Healthy Smoothie", "Sweet and Savory", ["apple juice", "juice", "simple", "easy", "pear", "spinach", "cinnamon", "apple juice", "healthy", "vegan", "paleo"], ["apple juice (1 cup)", "1 pear (cored/sliced)", "spinach (1 cup)", "ground cinnamon (1 tsp)", "1 apple (cored)"], "From Ginger Paro.");
    add("Winter Detox Orange", "Sweet and Savory", ["orange", "oranges", "ginger", "carrot", "turmeric", "pepper", "vegan", "paleo"], ["2 naval oranges (quartered, peeled)", "1 large carrot (chopped)", "1 – 1/2-inch piece of turmeric (peeled) OR 1 tsp powdered", "1 pinch black pepper", "1 cup ice cubes", "water (if needed for thinness)"], "Add all the ingredients + 1 cup of ice cubes to your high-powered blender (I love my Vitamix for this) and blend on high until smooth. Add up to a cup of water for a more juice-like smoothie. From www.theendlessmeal.com");
    add("Apple Peanut Butter Banana Smoothie", "Sweet", ["apple", "peanut butter", "banana", "bananas", "vanilla", "cinnamon", "ceylon", "almond milk", "oats"], ["1 big sweet apple (cored)", "1 ripe banana", "1 tbsp peanut butter", "2 tbsp oats", "1 tsp ceylon cinnamon", "1/2 teaspoon vanilla extract", "1/2 cup almond milk"], "Mix all ingredients in a blender until smooth and creamy. Add more water or milk to make the smoothie thinner. Serves one. From www.natalieshealth.com");
    add("Chocolate and Date Smoothie", "Sweet", ["chocolate", "date", "dates", "cinammon", "cocoa", "oats", "sweet", "dessert"], ["oats (1/3 cup)", "milk (1 cup)", "4 dates", "1 pinch cinammon", "1 dollop peanut butter"], "Mix all ingredients in a blender until smooth and creamy. Serves four. From Fuss Free Flavours.");
    add("Kale and Date Smoothie", "Sweet and Savory", ["banana", "date", "dates", "kale", "yogurt", "vanilla", "orange", "orange juice"], ["1/2 banana", "1 date", "orange juice (3 cups)", "1/2 cup kale", "1/2 cup vanilla yogurt", "ice cubes (optional)"], "Mix all ingredients in a blender until smooth and creamy. Add ice for thickness. Serves one. From Fuss Free Flavours.");
    add("Keto Smoothie", "Sweet", ["keto", "ketogenic", "strawberries", "strawberry", "raspberry", "raspberries", "blackberry", "blackberries", "coconut", "spinach"], ["1 & 1/2 cup frozen strawberries", "1 & 1/2 cup frozen raspberries plus more for garnish (optional)", "1 cup frozen blackberries", "2 cups coconut milk", "1 cup baby spinach", "shaved coconut for garnish (optional)"], "Mix all ingredients in a blender until smooth and creamy. Divide between cups and top with raspberries and coconut, if using.  Serves four. From delish.com.");
    add("Mango Pina Colada Smoothie", "Sweet", ["dessert", "sweet", "mango", "mangos", "pineapple", "pineapples", "coconut", "blackberries", "coconut", "cream", "pineapple juice"], ["1 mango - peeled, seeded, cubed", "1 & 1/4 cup ice cubes", "1 & 1/4 cup pineapple juice", "1/2 cup heavy cream", "1 can (14 oz) coconut milk", "2 tbsp white sugar"], "Mix all ingredients in a blender until smooth and creamy. Serves four. From allrecipes.com.");
    add("Jalapeno Green Smoothie", "Sweet and Savory", ["spicy", "banana", "spinach", "mango", "mangos", "jalapeno", "jalapenos", "simple", "pepper"], ["2 bananas - broken into chunks", "2 cups baby spinach", "1 cup frozen mango chunks", "1/2 tsp chopped jalapeno peppers (or to taste)", "1 cup water, or as desired"], "Layer banana, spinach, mango, and jalapeno pepper in a blender; add water and blend until smooth, adding more water for a thinner smoothie. From allrecipes.com.");
    add("Creamy Banana Strawberry Split Smoothie", "Sweet", ["sweet", "strawberry", "strawberries", "vanilla", "protein", "protein powder", "honey", "flax", "chia", "cinammon"], ["1 cup almond milk", "1 chopped frozen banana", "3/4 cup strawberries", "3 ice cubes", "1 scoop vanilla protein powder", "1 teaspoon vanilla extract", "1 teaspoon honey", "1 teaspoon ground flax seed", "1 teaspoon ground chia seeds", "1/2 teaspoon ground cinnamon"], "Blend all ingredients in a blender until smooth. From allrecipes.com.");
    add("Raw Mango Monster Smoothie", "Sweet and Savory", ["sweet", "flax", "pepitas", "pumpkin seeds", "pumpkin", "banana", "mango", "kale"], ["1 tablespoon flax seeds", "2 tablespoons pepitas (raw pumpkin seeds)", "1 ripe mango, cubed", "1 frozen banana, quartered", "1/3 cup water, or more to taste", "3 ice cubes", "2 leaves kale, or more to taste"], "1.) Blend flax seeds in a blender until finely ground; add pepitas and blend until ground, about 1 minute. 2.) Place mango, banana, water, ice cubes, and kale in the blender; blend until smooth, kale is fully incorporated, and the smoothie is uniform in color, about 3 minutes. Thin with more water to reach desired consistency.");
    add("Matcha Coconut Smoothie", "Sweet and Savory", ["superfood", "mango", "coconut", "green", "kale", "banana", "beans", "matcha", "tea"], ["1 banana", "1 cup frozen mango chunks", "2 leaves kale, torn into several pieces", "3 tablespoons white beans, drained", "2 tablespoons unsweetened shredded coconut", "1/2 teaspoon matcha green tea powder", "1 cup water"], "Combine banana, mango, kale, white beans, coconut, and matcha powder in a blender; add water. Blend mixture until very smooth.");
    add("Mango Cherry Smoothie", "Sweet", ["simple", "mango", "cherry", "cherries", "mangos"], ["2 cups pitted cherries", "1 cup chopped mango", "1 cup water", "1 cup ice cubes"], "Blend until smooth.");
    add("Cranberry Smoothie", "Sweet", ["simple", "berries", "cranberry", "cranberries", "banana"], ["1 cup almond milk", "1 banana", "1/2 cup frozen mixed berries", "1/2 cup fresh cranberries"], "From www.allrecipes.com.");
    add("Green Power Mojito Smoothie", "Sweet and Savory", ["savory", "mojito", "pineapple", "mint", "spinach", "banana", "orange", "lemon", "lime"], ["3 cups ice cubes, or as desired", "2 cups baby spinach leaves, or to taste", "1 (7 ounce) can crushed pineapple", "1/2 cup water, or to taste", "1 banana, broken into chunks", "1 orange, peeled and segmented", "10 fresh mint leaves, or more to taste", "1 lemon, juiced", "1 lime, juiced"], "Blend all ingredients in a blender until smooth. From allrecipes.com.");

    export default arrRecipes